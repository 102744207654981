<template>
    <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonBackButton/>
                </IonButtons>
                <IonTitle>Subscriptions & Billing</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <!-- <active-subscriptions> -->
            <IonList class="active-subscriptions-section">
                <IonListHeader>
                    <IonLabel>Active Subscriptions</IonLabel>
                </IonListHeader>
                <div class="flex flex--100 flex--x-align-center loading-spinner-section" v-if="isWaitingServerResponse">
                    <IonSpinner name="circles"/>
                </div>
                <!--
                <IonItem
                    lines="none"
                    v-for="subscription in activeSubscriptions"
                    :key="subscription.id"
                >
                    <UserSubscription :subscription="subscription"/>
                </IonItem>-->
                <IonItem lines="none" v-if="!isWaitingServerResponse && !activeCreatorSubscription">
                    <IonNote>No active subscriptions</IonNote>
                </IonItem>
                <IonItem v-if="activeCreatorSubscription">
                    Creator {{ activeCreatorSubscription.name }} Subscription
                </IonItem>
            </IonList>
            <!-- </active-subscriptions> -->
            <!-- <invoices> -->
            <IonList>
                <IonItem :button="true" @click="toInvoicesView">
                    <IonLabel>My Invoices</IonLabel>
                </IonItem>
            </IonList>
            <!-- </invoices> -->
            <!-- </plans> -->
            <IonList>
                <IonListHeader>
                    <IonLabel>Plans</IonLabel>
                </IonListHeader>
                <IonItem :button="true" @click="toCreatorPlansView">
                    <IonLabel>Creator Plans</IonLabel>
                </IonItem>
            </IonList>
            <!-- </plans> -->
        </IonContent>
    </IonPage>
</template>

<script>
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader, IonNote,
    IonPage, IonSpinner,
    IonTitle,
    IonToolbar,
} from "@ionic/vue";
import UserSubscription from "@/components/billings/UserSubscription.vue";
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
import { creatorPlans } from "@/utilities/PricingPlans";
import { userStore } from "@/main";
import { userManager } from "@/UserManager";

export default {
    name: "BillingView",
    components: {
        IonSpinner,
        UserSubscription,
        IonPage,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonList,
        IonListHeader,
        IonItem,
        IonLabel,
        IonButtons,
        IonBackButton,
        IonButton,
        IonNote,
    },
    data () {
        return {
            isWaitingServerResponse: false,
            activeSubscriptions: [],
        };
    },
    computed: {
        ...mapStores(useUserStore),

        activeCreatorSubscription () {
            const {
                activeCreatorSubscriptionType,
            } = this.userStore.user.billing;

            return creatorPlans.find((plan) => plan.levelType === activeCreatorSubscriptionType);
        },
    },
    methods: {
        toInvoicesView () {
            this.$router.push("/user/invoices");
        },

        toCreatorPlansView () {
            this.$router.push("/creator/plans");
        },

        async fetch () {
            this.isWaitingServerResponse = true;

            /*
            try {
                const {
                    activeSubscriptions,
                } = await userManager.getActiveSubscriptions();
                this.activeSubscriptions = activeSubscriptions;
            }
            catch {
                // Silence is golden
            }*/
            await Promise.all([
                userManager.billing.notifyStripeEvent(),
                userManager.billing.notifyRevenueCatEvent(),
            ]);
            await userStore.fetchUser();

            this.isWaitingServerResponse = false;
        },
    },
    created () {
        this.fetch();
    },
    ionViewWillEnter () {
        this.fetch();
    },
};
</script>

<style lang="scss" scoped>
.active-subscriptions-section {
    margin-bottom: 21px;
}

.loading-spinner-section {
    margin: 21px 0;
}
</style>
