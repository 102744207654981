<template>
    <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="end">
                    <IonButton :disabled="!canNext" @click="toFeedView">
                        Next
                        <IonIcon :icon="rightArrowIcon"/>
                    </IonButton>
                </IonButtons>
            </IonToolbar>
            <IonToolbar>
                <div class="flex flex--100 header-section">
                    <div class="flex flex--100 flex--x-align-center">
                        <h2 class="title">
                            <template v-if="!canNext">
                                What are you interested in?
                            </template>
                            <template v-else>
                                Great Job!
                            </template>
                        </h2>
                    </div>
                    <div class="flex flex--100 flex--x-align-center">
                    <span class="subtitle">
                        <template v-if="!canNext">
                            {{ this.followedCategories.length }} of {{ MIN_CATEGORIES_TO_FOLLOW }}
                        </template>
                        <template v-else>
                            You can start exploring Vottify
                        </template>
                    </span>
                    </div>
                </div>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <div class="flex flex--100">
                <SimpleCategory
                    v-for="category in topLevelCategories"
                    :key="category.id"
                    :category="category"
                />
            </div>
            <hr class="safe-bottom-area"/>
        </IonContent>
    </IonPage>
</template>

<script>
import {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
} from "@ionic/vue";
import {mapStores} from "pinia";
import {useUserStore} from "@/stores/UserStore";

import { chevronForward, } from "ionicons/icons";
import UserAvatar from "@/components/UserAvatar.vue";
import {userManager} from "@/UserManager";
import SimpleCategory from "@/components/categories/SimpleCategory.vue";

const MIN_CATEGORIES_TO_FOLLOW = 3;

export default {
    name: "MandatoryCategorySelectionView",
    components: {
        SimpleCategory,
        UserAvatar,
        IonPage,
        IonHeader,
        IonToolbar,
        IonContent,
        IonButton,
        IonButtons,
        IonIcon,
    },
    data () {
        return {
            categories: [],
        };
    },
    computed: {
        ...mapStores(useUserStore),

        MIN_CATEGORIES_TO_FOLLOW () {
            return MIN_CATEGORIES_TO_FOLLOW;
        },

        loggedUser () {
            return this.userStore.user;
        },

        enabledCategories () {
            return this.categories.filter((category) => !category.isArchived);
        },

        topLevelCategories () {
            return this.enabledCategories.filter((category) => !category.parentCategory);
        },

        followedCategories () {
            return this.loggedUser.metrics.followedCategories;
        },

        rightArrowIcon () {
            return chevronForward;
        },

        canNext () {
            return this.followedCategories.length >= this.MIN_CATEGORIES_TO_FOLLOW;
        },
    },
    methods: {
        async fetch () {
            this.categories = await userManager.getAllCategories();
        },

        toFeedView () {
            this.$router.push("/navigation/feed");
        },
    },
    created () {
        this.fetch();
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.title {
    margin: 0;
    padding: 0;

    font-size: 19px;
    font-weight: 600;
    letter-spacing: -0.02rem;
    color: $base-text-color;
}

.subtitle {
    margin-top: 6px;

    font-size: 15px;
    font-weight: 400;
    color: $base-text-color;
}

.header-section {
    padding: 20px;

    background-color: rgb(255, 255, 255);
    //border-top: $base-border;
}
</style>
