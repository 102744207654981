<template>
    <div id="open-image-action-sheet" class="flex user-avatar" :style="style">
        <template v-if="canEdit">
            <input class="image-input" type="file" accept="image/*" ref="imageInput" @change="onImageChanged">
            <IonActionSheet trigger="open-image-action-sheet" :buttons="actionSheetButtons"/>
        </template>
    </div>
</template>

<script>
import { IonActionSheet, } from "@ionic/vue";
import { userManager, } from "@/UserManager";
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";

const DEFAULT_PRIMARY_IMAGE_URI = "assets/avatar.svg";

export default {
    name: "UserAvatar",
    components: {
        IonActionSheet,
    },
    props: {
        user: {
            type: Object,
        },
        canEdit: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapStores(useUserStore),

        profileImageUri () {
            let profileImageUri;

            if (this.user) {
                profileImageUri = this.user.profileImageUri;
            }

            return profileImageUri;
        },
        style () {
            return {
                "background-image": `url("${this.profileImageUri}")`,
            };
        },

        actionSheetButtons () {
            const buttons = [
                {
                    text: "Change",
                    handler: () => this.presentImageGallery(),
                },
            ];

            if (this.user.profileImageUri) {
                buttons.push({
                    text: "Delete",
                    role: "destructive",
                    handler: async () => await this.deleteImage(),
                });
            }

            buttons.push({
                text: "Dismiss",
                role: "cancel",
            });

            return buttons;
        }
    },
    methods: {
        async deleteImage () {
            if (!this.user.profileImage) {
                return;
            }

            await userManager.removeImage(this.user.profileImage);

            this.user.profileImage = null;
        },

        async onImageChanged (e) {
            const image = e.target.files[0];

            if (image) {
                await this.updatePrimaryImage(image);
            }
        },

        async updatePrimaryImage (image) {
            const response = await userManager.user.updatePrimaryImage(image);
            const { user, } = response.data.result;

            this.userStore.update(user);
        },

        presentImageGallery () {
            this.$refs.imageInput.click();
        },
    }
};
</script>

<style lang="scss" scoped>
.user-avatar {
    background-color: rgb(240, 240, 240);
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid rgb(200, 200, 200);
    border-radius: 50%;
}

.image-input {
    display: none;
}
</style>
