<template>
    <div class="flex flex--100 plan">
        <div class="flex flex--100 plan-features-section">
            <div class="flex flex--100 flex--y-align-center feature-section">
                <ApplicationIcon/>
                <span class="feature">5 Polls</span>
                <span class="monthly">month</span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section">
                <ApplicationIcon/>
                <span class="feature">40.000 Votes</span>
                <span class="monthly">month</span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section">
                <ApplicationIcon/>
                <span class="feature">Poll Feed Algorithm</span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section">
                <ApplicationIcon/>
                <span class="feature">Max. 4 Poll Options</span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section">
                <ApplicationIcon/>
                <span class="feature">
                    Limited Poll Analytics<!--<br>
                    (Age, Gender, Income Range)-->
                </span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section">
                <ApplicationIcon/>
                <span class="feature">
                    Voter Verification<!--<br>
                    (Email, Mobile Number)-->
                </span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section">
                <ApplicationIcon/>
                <span class="feature">Export Poll Data</span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section feature-section--disabled">
                <ApplicationIcon/>
                <div class="feature">
                    Poll Actions<!--<br>
                    (Donation, Petition, etc.)-->
                </div>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section feature-section--disabled">
                <ApplicationIcon/>
                <span class="feature">
                    Targeted Polls<!--<br>
                    (Age, Gender, Income Range, etc.)-->
                </span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section feature-section--disabled">
                <ApplicationIcon/>
                <span class="feature">
                    Collaboration on Polls
                </span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section feature-section--disabled">
                <ApplicationIcon/>
                <div class="feature">
                    Automated Poll Sharing Across Social Media
                    Accounts
                </div>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section feature-section--disabled">
                <ApplicationIcon/>
                <span class="feature">
                    Poll Comments Sentiment Analysis
                </span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section feature-section--disabled">
                <ApplicationIcon/>
                <span class="feature">
                    Poll Options as Images
                </span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section feature-section--disabled">
                <ApplicationIcon/>
                <span class="feature">
                    Group Polls to Survey
                </span>
            </div>
            <div class="flex flex--100 flex--y-align-center feature-section feature-section--disabled">
                <ApplicationIcon/>
                <span class="feature">
                    Recurrent Poll
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import ApplicationIcon from "@/components/ApplicationIcon.vue";
import { defineComponent, } from "vue";

export default defineComponent({
    name: "LightCreatorPlan",
    components: {
        ApplicationIcon,
    },
});
</script>

<style lang="scss" scoped>
@import "@/theme/pricing-plans.scss";
</style>
