<template>
    <IonPage>
        <IonHeader>
            <IonToolbar class="toolbar">
                <IonButtons slot="start">
                    <IonBackButton/>
                </IonButtons>
                <IonButtons slot="end">
                    <FollowButton
                        class="toolbar-follow-button"
                        :value="isFollowed"
                        v-show="!headerIsVisible"
                        @click="toggleFollow"
                    />
                </IonButtons>
            </IonToolbar>
        </IonHeader>
        <IonContent class="ion-content">

            <div class="flex flex--100 flex--x-align-center header" ref="header">
                <div class="flex flex--100 flex--y-align-center" v-if="category">
                    <div class="flex">
                        <SimpleCategoryIcon :alternative-id="category.alternativeId"/>
                    </div>
                    <div class="flex primary-section">
                        <div class="flex flex--100 flex--x-align-between category-statistics-section">
                            <div class="flex flex--x-align-center counter">
                                <span
                                    class="flex flex--100 flex--x-align-center counter__value"
                                >
                                    {{ visiblePollsCounter }}
                                </span>
                                polls
                            </div>
                            <div class="flex flex--x-align-center counter">
                                <span
                                    class="flex flex--100 flex--x-align-center counter__value"
                                >
                                    {{ visibleVotesCounter }}
                                </span>
                                votes
                            </div>
                            <div class="flex flex--x-align-center counter">
                                <span
                                    class="flex flex--100 flex--x-align-center counter__value"
                                >
                                    {{ visibleFollowersCounter }}
                                </span>
                                followers
                            </div>
                        </div>
                    </div>
                    <div class="flex flex--100 name-section">
                        <span class="name">{{ visibleName }}</span>
                    </div>
                    <div class="flex flex--100">
                        <FollowButton
                            class="header-follow-button"
                            :value="isFollowed"
                            @click="toggleFollow"
                        />
                    </div>
                </div>
            </div>

            <div class="flex flex--100 activity-selector">
                <IonSegment :value="selectedActivity" v-model="selectedActivity" class="activity-segment">
                    <IonSegmentButton :value="CategoryActivityType.LATEST" class="activity-segment-button">
                        <GridIcon class="activity-segment-icon"/>
                    </IonSegmentButton>
                    <!--
                    <IonSegmentButton :value="CategoryActivityType.TRENDING" class="activity-segment-button">
                        <TrendingIcon class="activity-segment-icon"/>
                    </IonSegmentButton>
                    <IonSegmentButton :value="CategoryActivityType.VOTES" class="activity-segment-button">
                        <OpenBookIcon class="activity-segment-icon"/>
                    </IonSegmentButton>
                    -->
                </IonSegment>
            </div>

            <div class="flex flex--100 created-polls-section" v-show="mustShowLatestPolls">

                <SimplePollSquarePreview
                    v-for="{ id, } in openPolls"
                    :key="id"
                    :id="id"
                    @click="toPollView(id)"
                ></SimplePollSquarePreview>

            </div>

            <hr class="safe-bottom-area"/>

        </IonContent>
    </IonPage>
</template>

<script>
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonLabel,
    IonPage,
    IonSegment,
    IonSegmentButton,
    IonToolbar,
} from "@ionic/vue";
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
import UserAvatar from "@/components/UserAvatar.vue";
import FollowButton from "@/components/buttons/FollowButton.vue";
import { userManager } from "@/UserManager";
import { numberToCompactString } from "@/utilities/Utilities";
import VerifiedBadgeIcon from "@/components/icons/VerifiedBadgeIcon.vue";
import SimpleButton from "@/components/buttons/SimpleButton.vue";
import { usePollsStore } from "@/stores/PollsStore";
import SimplePollSquarePreview from "@/components/polls/SimplePollSquarePreview.vue";
import SimpleCategoryIcon from "@/components/categories/SimpleCategoryIcon.vue";
import OpenBookIcon from "@/components/icons/OpenBookIcon.vue";
import UserActivityIcon from "@/components/icons/UserActivityIcon.vue";
import GridIcon from "@/components/icons/GridIcon.vue";
import TrendingIcon from "@/components/icons/TrendingIcon.vue";

const CategoryActivityType = Object.freeze({
    LATEST: 0,
    TRENDING: 1,
    VOTES: 2,
});

export default {
    name: "CategoryView",
    components: {
        TrendingIcon,
        GridIcon, UserActivityIcon, OpenBookIcon,
        SimpleCategoryIcon,
        SimplePollSquarePreview,
        SimpleButton,
        IonBackButton,
        VerifiedBadgeIcon,
        FollowButton,
        UserAvatar,
        IonPage,
        IonHeader,
        IonToolbar,
        IonContent,
        IonButton,
        IonButtons,
        IonIcon,
        IonSegment,
        IonSegmentButton,
        IonLabel,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            isWaitingServerResponse: false,
            category: undefined,
            openPolls: [],
            closedPolls: [],
            selectedActivity: CategoryActivityType.LATEST,

            headerIsVisible: true,
        };
    },
    computed: {
        ...mapStores(useUserStore),
        ...mapStores(usePollsStore),

        loggedUser () {
            return this.userStore.user;
        },

        visibleName () {
            return this.category.name;
        },

        visibleFollowersCounter () {
            return numberToCompactString(this.category.metrics.followersCounter);
        },

        visiblePollsCounter () {
            return numberToCompactString(this.category.metrics.pollsCounter);
        },

        visibleVotesCounter () {
            return numberToCompactString(this.category.metrics.votesCounter);
        },

        isFollowed () {
            return !!this.userStore.getFollowedCategory(this.id);
        },

        mustShowLatestPolls () {
            return this.selectedActivity === CategoryActivityType.LATEST;
        },

        CategoryActivityType () {
            return CategoryActivityType;
        },
    },
    methods: {
        async toggleFollow () {
            if (!this.isFollowed) {
                await this.follow(this.id);
            }
            else {
                await this.unfollow(this.id);
            }
        },

        async follow () {
            const {
                user,
                category,
            } = await userManager.followCategory(this.id);

            this.category = category;
            this.userStore.update(user);
        },

        async unfollow () {
            const {
                user,
                category,
            } = await userManager.unfollowCategory(this.id);

            this.category = category;
            this.userStore.update(user);
        },

        async fetch () {
            if (this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;

            const {
                category,
            } = await userManager.getCategory(this.id);

            this.category = category;

            this.isWaitingUserServerResponse = false;

            await this.fetchOpenPolls();
        },

        async fetchOpenPolls () {
            const {
                openPolls,
            } = await userManager.getCategoryOpenPolls(this.id);
            this.pollsStore.updateMany(openPolls);

            this.openPolls = openPolls;
        },

        async fetchClosedPolls () {
            const {
                closedPolls,
            } = await userManager.getCategoryOpenPolls(this.id);
            this.pollsStore.updateMany(closedPolls);

            this.closedPolls = closedPolls;
        },

        toPollView (pollId) {
            this.$router.push(`/navigation/polls/${pollId}`);
        },
    },
    created () {
        this.fetch();
    },
    mounted () {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                this.headerIsVisible = entry.isIntersecting;
            });
        }, {
            threshold: 0,
        });

        observer.observe(this.$refs.header);
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";
@import "@/theme/mixins.scss";

@include activity-segment;

.ion-content {
    &::part(background) {
        background-color: rgb(250, 250, 250);
    }
}

.header {
    padding: 23px;

    background-color: rgb(250, 250, 250);
    border-bottom: $base-border;
}

.name {
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0.002rem;
    color: rgb(33, 33, 33);
}

.primary-section {
    flex: 1;

    padding-left: 7px;
}

.toolbar {
    --background: rgb(250, 250, 250);
}

.simple-category-icon {
    width: 71px;

    ::v-deep(.category-icon) {
        width: 71px;
        height: 71px;
    }
}

.category-statistics-section {

}

.counter {
    flex: 1;

    margin: 0;
    padding: 0;

    font-weight: 400;
    color: $base-text-color;
    font-size: 14px;

    &__value {
        font-weight: 700;
    }
}

.safe-bottom-area {
    margin: 0;
    padding: 0;

    height: 11px;
}

.header-follow-button {
    margin-top: 13px;
}

.name-section {
    margin-top: 7px;
}

.activity-selector {
    position: sticky;
    top: 0;
}
</style>
