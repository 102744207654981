<template>
    <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonBackButton/>
                </IonButtons>
                <IonTitle>My Categories</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <div class="flex flex--100 flex--x-align-center discover-categories-section">
                <IonButton fill="clear" @click="toDiscoverCategoriesView">
                    Discover Categories
                    <IonIcon :icon="chevronForward"/>
                </IonButton>
            </div>
            <div class="flex flex--100">
                <SimpleCategory
                    v-for="category in localVisibleCategories"
                    :key="category.id"
                    :category="category"
                />
            </div>
            <hr class="safe-bottom-area"/>
        </IonContent>
    </IonPage>
</template>

<script>
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonPage,
    IonTitle,
    IonToolbar,
} from "@ionic/vue";
import {mapStores} from "pinia";
import {useUserStore} from "@/stores/UserStore";
import UserAvatar from "@/components/UserAvatar.vue";
import {userManager} from "@/UserManager";
import SimpleCategory from "@/components/categories/SimpleCategory.vue";
import {cloneDeep} from "lodash/lang";
import {chevronForward,} from "ionicons/icons";

export default {
    name: "MyCategoriesView",
    components: {
        IonTitle, IonBackButton,
        SimpleCategory,
        UserAvatar,
        IonPage,
        IonHeader,
        IonToolbar,
        IonContent,
        IonButton,
        IonButtons,
        IonIcon,
    },
    data () {
        return {
            categories: [],
            localVisibleCategories: [],
        };
    },
    computed: {
        ...mapStores(useUserStore),

        loggedUser () {
            return this.userStore.user;
        },

        enabledCategories () {
            return this.categories.filter((category) => !category.isArchived);
        },

        followedCategories () {
            return this.enabledCategories.filter((category) => {
                return this.loggedUser.metrics.followedCategories.some(
                    (followedCategory) => category.id === followedCategory.category
                );
            });
        },

        chevronForward () {
            return chevronForward;
        },
    },
    methods: {
        async fetch () {
            this.categories = await userManager.getAllCategories();
        },

        toDiscoverCategoriesView () {
            this.$router.push("/categories");
        },

        resetVisibleCategories () {
            this.localVisibleCategories = cloneDeep(this.followedCategories);
        },
    },
    async created () {
        await this.fetch();
        this.resetVisibleCategories();
    },
    async ionViewWillEnter () {
        if (this) {
            await this.fetch();
            this.resetVisibleCategories();
        }
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.discover-categories-section {
    border-bottom: $base-border;
}
</style>
