<template>
    <IonPage>
    <IonHeader>
        <IonToolbar>
            <IonButtons slot="start">
                <IonBackButton text=""/>
            </IonButtons>
            <IonTitle>My Identity</IonTitle>
            <IonButtons slot="end">
                <IonButtons slot="end">
                    <IonButton
                        class="update-button"
                        :disabled="isWaitingServerResponseForUpdate"
                        v-show="hasChangedConfiguration"
                        @click="update"
                    >
                        Update
                    </IonButton>
                </IonButtons>
            </IonButtons>
        </IonToolbar>
    </IonHeader>
    <IonContent>
        <div class="flex flex--100 header-section">
            <p class="change-identity-text" v-if="!isCreator">
                Your identity details can be updated once per month<br>
                <strong v-show="!canUpdateIdentity">
                    You can update your identity in {{ remainingDays }} days
                </strong>
                <br><br>
                You can update your biography at any time
            </p>
            <p class="change-identity-text" v-else>
                Please contact us to update your creator and/or representative identity
                <br><br>
                You can update your biography at any time
            </p>
        </div>
        <IonList>
            <IonListHeader>
                <IonLabel>Biography</IonLabel>
            </IonListHeader>
            <IonItemGroup>
                <IonItem>
                    <IonTextarea
                        placeholder="Introduce yourself"
                        v-model="localUser.biography"
                        :value="localUser.biography"
                        :auto-grow="true"
                        :rows="1"
                    ></IonTextarea>
                </IonItem>
            </IonItemGroup>
        </IonList>
        <!-- <base-user> -->
        <IonList v-if="!isCreator">
            <IonListHeader>
                <IonLabel>
                    Identity
                </IonLabel>
            </IonListHeader>
            <IonItemGroup>
                <IonItem>
                    <IonInput
                        type="text"
                        label="First name"
                        label-placement="floating"
                        v-model="localUser.firstName"
                        :disabled="!canUpdateIdentity"
                    ></IonInput>
                </IonItem>
                <IonItem>
                    <IonInput
                        type="text"
                        label="Last name"
                        label-placement="floating"
                        v-model="localUser.lastName"
                        :disabled="!canUpdateIdentity"
                    ></IonInput>
                </IonItem>
                <IonItem>
                    <IonInput
                        type="number"
                        v-model="age"
                        label="Age"
                        label-placement="floating"
                        :disabled="!canUpdateIdentity"
                    ></IonInput>
                </IonItem>
                <IonItem>
                    <IonSelect
                        v-model="localUser.genderType"
                        label="Gender"
                        label-placement="floating"
                        interface="action-sheet"
                        :disabled="!canUpdateIdentity"
                    >
                        <IonSelectOption
                            v-for="value in Object.values(GenderType)"
                            :key="value"
                            :value="value"
                        >
                            {{ $t(`genderType.${value}`) }}
                        </IonSelectOption>
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonSelect
                        v-model="localUser.incomeRangeType"
                        label="Income Range"
                        label-placement="floating"
                        interface="action-sheet"
                        :disabled="!canUpdateIdentity"
                    >
                        <IonSelectOption
                            v-for="value in Object.values(IncomeRangeType)"
                            :key="value"
                            :value="value"
                        >
                            {{ $t(`incomeRangeType.${value}`) }}
                        </IonSelectOption>
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonSelect
                        v-model="localUser.educationType"
                        label="Education"
                        label-placement="floating"
                        interface="action-sheet"
                        :disabled="!canUpdateIdentity"
                    >
                        <IonSelectOption
                            v-for="value in Object.values(EducationType)"
                            :key="value"
                            :value="value"
                        >
                            {{ $t(`educationType.${value}`) }}
                        </IonSelectOption>
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonSelect
                        v-model="localUser.religionType"
                        label="Religion"
                        label-placement="floating"
                        interface="action-sheet"
                        :disabled="!canUpdateIdentity"
                    >
                        <IonSelectOption
                            v-for="value in Object.values(ReligionType)"
                            :key="value"
                            :value="value"
                        >
                            {{ $t(`religionType.${value}`) }}
                        </IonSelectOption>
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonSelect
                        v-model="localUser.industryType"
                        label="Industry"
                        label-placement="floating"
                        interface="action-sheet"
                        :disabled="!canUpdateIdentity"
                    >
                        <IonSelectOption
                            v-for="value in Object.values(IndustryType)"
                            :key="value"
                            :value="value"
                        >
                            {{ $t(`industryType.${value}`) }}
                        </IonSelectOption>
                    </IonSelect>
                </IonItem>
            </IonItemGroup>
        </IonList>
        <!-- </base-user> -->
        <!-- <creator> -->
        <IonList v-if="isCreator">
            <IonListHeader>
                <IonLabel>
                    Creator Identity
                </IonLabel>
            </IonListHeader>
            <!-- <organization> -->
            <IonItemGroup v-if="localUser.creator.type === CreatorType.ORGANIZATION">
                <IonItem>
                    <IonInput
                        type="text"
                        label="Company Name"
                        label-placement="floating"
                        v-model="localUser.creator.organization.companyName"
                        :disabled="true"
                    ></IonInput>
                </IonItem>
            </IonItemGroup>
            <!-- </organization> -->
            <!-- <public-figure> -->
            <IonItemGroup v-if="localUser.creator.type === CreatorType.PUBLIC_FIGURE">
                <IonItem>
                    <IonInput
                        type="text"
                        label="Preferred Name"
                        label-placement="floating"
                        v-model="localUser.creator.publicFigure.preferredName"
                        :disabled="true"
                    ></IonInput>
                </IonItem>
            </IonItemGroup>
            <!-- </public-figure> -->
        </IonList>
        <!-- <creator-representative> -->
        <IonList v-if="isCreator">
            <IonListHeader>
                <IonLabel>
                    Representative Identity
                </IonLabel>
            </IonListHeader>
            <IonItemGroup>
                <IonItem>
                    <IonInput
                        type="text"
                        label="First name"
                        label-placement="floating"
                        v-model="localUser.firstName"
                        :disabled="true"
                    ></IonInput>
                </IonItem>
                <IonItem>
                    <IonInput
                        type="text"
                        label="Last name"
                        label-placement="floating"
                        v-model="localUser.lastName"
                        :disabled="true"
                    ></IonInput>
                </IonItem>
                <IonItem>
                    <IonInput
                        type="number"
                        v-model="age"
                        label="Age"
                        label-placement="floating"
                        :disabled="true"
                    ></IonInput>
                </IonItem>
                <IonItem>
                    <IonSelect
                        v-model="localUser.genderType"
                        label="Gender"
                        label-placement="floating"
                        interface="action-sheet"
                        :disabled="true"
                    >
                        <IonSelectOption
                            v-for="value in Object.values(GenderType)"
                            :key="value"
                            :value="value"
                        >
                            {{ $t(`genderType.${value}`) }}
                        </IonSelectOption>
                    </IonSelect>
                </IonItem>

            </IonItemGroup>
        </IonList>
        <!-- </>creator-representative> -->
        <!-- </creator> -->
    </IonContent>
    </IonPage>
</template>

<script>
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCheckbox,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonItemGroup,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonTitle,
    IonToggle,
    IonToolbar,
} from "@ionic/vue";
import { userManager, } from "@/UserManager";
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
import { cloneDeep } from "lodash/lang";
import {
    CreatorType,
    EducationType,
    GenderType,
    IncomeRangeType,
    IndustryType,
    ReligionType
} from "@/utilities/Utilities";

const minDaysSinceLastIdentityUpdate = 30;

export default {
    name: "MyIdentityView",
    components: {
        IonTextarea,
        IonSelect, IonSelectOption,
        IonItemGroup, IonInput,
        IonIcon,
        IonPage,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonList,
        IonListHeader,
        IonItem,
        IonLabel,
        IonButtons,
        IonBackButton,
        IonButton,
        IonCheckbox,
        IonToggle,
    },
    data () {
        return {
            localUser: {},
            isWaitingServerResponseForUpdate: false,
        };
    },
    computed: {
        CreatorType() {
            return CreatorType
        },

        ...mapStores(useUserStore),

        age: {
            get () {
                return (new Date()).getUTCFullYear() - this.localUser.birthYear;
            },
            set (value) {
                this.localUser.birthYear = (new Date()).getUTCFullYear() - value;
            },
        },

        lastIdentityUpdateAt () {
            return new Date(this.loggedUser.lastIdentityUpdateAt);
        },

        canUpdateIdentity () {
            return Date.now() - this.lastIdentityUpdateAt.getTime() > minDaysSinceLastIdentityUpdate * 86400000
        },

        remainingDays () {
            return (
                minDaysSinceLastIdentityUpdate
                - (Date.now() - this.lastIdentityUpdateAt.getTime()) / 86400000
            ).toFixed(0);
        },

        GenderType () {
            return GenderType;
        },

        IncomeRangeType () {
            return IncomeRangeType;
        },

        EducationType () {
            return EducationType;
        },

        ReligionType () {
            return ReligionType;
        },

        IndustryType () {
            return IndustryType
        },

        loggedUser () {
            return this.userStore.user;
        },

        isCreator () {
            return this.loggedUser.isCreator;
        },

        hasChangedIdentity () {
            return (
                this.loggedUser.firstName !== this.localUser.firstName
                || this.loggedUser.lastName !== this.localUser.lastName
                || this.loggedUser.genderType !== this.localUser.genderType
                || this.loggedUser.incomeRangeType !== this.localUser.incomeRangeType
                || this.loggedUser.educationType !== this.localUser.educationType
                || this.loggedUser.religionType !== this.localUser.religionType
                || this.loggedUser.industryType !== this.localUser.industryType
            );
        },

        hasChangedBiography () {
            return (
                this.loggedUser.biography !== this.localUser.biography
            );
        },

        hasChangedConfiguration () {
            return (
                this.hasChangedIdentity
                || this.hasChangedBiography
            );
        },
    },

    methods: {
        async updateIdentity () {
            try {
                const response = await userManager.user.updateIdentity(this.localUser);
                const { user: updatedUser, } = response.data.result;

                return updatedUser;
            }
            catch {
                // Silence is golden
            }

            return undefined;
        },

        async updateBiography () {
            try {
                const response = await userManager.user.updateBiography(this.localUser.biography);
                const { user: updatedUser, } = response.data.result;

                return updatedUser;
            }
            catch {
                // Silence is golden
            }

            return undefined;
        },

        async update () {
            if (this.isWaitingServerResponseForUpdate) {
                return;
            }

            this.isWaitingServerResponseForUpdate = true;
            let updatedUser = undefined;

            if (this.hasChangedIdentity && this.canUpdateIdentity) {
                updatedUser = await this.updateIdentity() ?? updatedUser;
            }

            if (this.hasChangedBiography) {
                updatedUser = await this.updateBiography() ?? updatedUser;
            }

            if (updatedUser) {
                this.userStore.update(updatedUser);
                this.resetLocalConfiguration();
            }

            this.isWaitingServerResponseForUpdate = false;
        },

        resetLocalConfiguration () {
            this.localUser = cloneDeep(this.loggedUser);
        },
    },
    created () {
        this.resetLocalConfiguration();
    },
    ionViewDidLeave () {
        this.resetLocalConfiguration();
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.header-section {
    padding: 20px;

    border-bottom: $base-border;
}

.change-identity-text {
    margin: 0;
    padding: 0;

    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.01rem;

    color: $base-text-color;
}

.update-button {
    cursor: pointer;

    padding: 1px 9px;

    background-image: linear-gradient(45deg, rgb(233, 233, 233), rgb(247, 247, 247));
    border-radius: 7px;

    font-weight: 500;
}
</style>
