<template>
    <IonPage>
        <IonContent>
            <MainNavigationBar/>
            <div class="flex flex--100">
                <div class="flex flex--100 flex--x-align-center">
                    <h2 class="title">
                        Business Pricing
                    </h2>
                </div>
                <div class="flex flex--100 flex--x-align-center">
                    <div class="flex plan-section">
                        <div class="flex flex--100 plan-pricing-section">
                            <h2 class="plan-name">Light</h2>
                            <span class="plan-price">
                                    29.99€
                                    <span class="plan-price-interval">/ month</span>
                                </span>
                            <div class="flex flex--100 flex--x-align-center">
                                    <span class="plan-action-button" @click="toMarketplaceView">
                                        Try Now
                                    </span>
                            </div>
                        </div>
                        <LightCreatorPlan/>
                    </div>
                    <div class="flex plan-section">
                        <div class="flex flex--100 plan-pricing-section">
                            <h2 class="plan-name">Standard</h2>
                            <span class="plan-price">
                                    59.99€
                                    <span class="plan-price-interval">/ month</span>
                                </span>
                            <div class="flex flex--100 flex--x-align-center">
                                    <span class="plan-action-button" @click="toMarketplaceView">
                                        Try Now
                                    </span>
                            </div>
                        </div>
                        <StandardCreatorPlan/>
                    </div>
                    <div class="flex plan-section">
                        <div class="flex flex--100 plan-pricing-section">
                            <h2 class="plan-name">Premium</h2>
                            <span class="plan-price">
                                    99.99€
                                    <span class="plan-price-interval">/ month</span>
                                </span>
                            <div class="flex flex--100 flex--x-align-center">
                                    <span class="plan-action-button" @click="toMarketplaceView">
                                        Try Now
                                    </span>
                            </div>
                        </div>
                        <PremiumCreatorPlan/>
                    </div>
                </div>
            </div>
            <div class="flex flex--100 flex--x-align-center">
                <CreatorPricingFaq/>
            </div>
            <MainFooter/>
        </IonContent>
    </IonPage>
</template>

<script>
import { IonContent, IonPage, } from "@ionic/vue";
import ApplicationIcon from "@/components/ApplicationIcon.vue";
import StandardCreatorPlan from "@/components/creators/plans/StandardCreatorPlan.vue";
import LightCreatorPlan from "@/components/creators/plans/LightCreatorPlan.vue";
import PremiumCreatorPlan from "@/components/creators/plans/PremiumCreatorPlan.vue";
import MainFooter from "@/components/website/WebsiteMainFooter.vue";
import MainNavigationBar from "@/components/website/WebsiteMainNavigationBar.vue";
import CreatorPricingFaq from "@/components/mixed/CreatorPricingFaq.vue";
import { APP_STORE_URI, } from "@/utilities/Utilities";
import WebsiteLightHeader from "@/components/website/TargetCustomerHeader.vue";
import ApplicationMarketplaceButtons from "@/components/website/ApplicationMarketplaceButtons.vue";

export default {
    name: "CreatorPlansLargeView",
    components: {
        ApplicationMarketplaceButtons,
        WebsiteLightHeader,
        CreatorPricingFaq,
        MainNavigationBar,
        MainFooter,
        PremiumCreatorPlan,
        LightCreatorPlan,
        StandardCreatorPlan,
        ApplicationIcon,
        IonPage,
        IonContent,
    },
    methods: {
        toMarketplaceView () {
            window.open(APP_STORE_URI, "_blank");
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/theme/palette";

.title {
    margin: 60px 0;
    padding: 0;

    font-size: 51px;
    font-weight: 800;
    letter-spacing: 0.002rem;
    text-align: center;

    color: $base-text-color;
}

.plan-section {
    overflow: hidden;

    width: 30%;
    max-width: 445px;
    margin: 6px;

    border: 1px solid rgb(220, 220, 220);
    border-radius: 12px;

    @media (max-width: $mobile-breaking-point) {
        width: 100%;
    }
}

.plan-pricing-section {
    padding: 23px;

    background-color: rgb(255, 255, 255);
    background-image: linear-gradient(45deg, rgb(0, 0, 0) 50%, $brand-color);
}

.plan-name {
    width: 100%;

    margin: 0;
    font-size: 29px;
    font-weight: 600;
    color: rgb(255, 255, 255);
    text-align: center;
}

.plan-price {
    width: 100%;

    margin: 13px 0;
    font-size: 31px;
    font-weight: 600;
    color: rgb(255, 255, 255);
    text-align: center;
}

.plan-price-interval {
    font-size: 15px;
    font-weight: 400;
    color: rgb(211, 211, 211);
}

.plan-action-button {
    cursor: pointer;

    margin: 7px 0 0 0;
    padding: 11px 31px;
    border-radius: 99px;

    background-color: $brand-color;

    font-weight: 500;
    font-size: 19px;
    color: rgb(255, 255, 255);

    letter-spacing: 0.008rem;
}

.creator-pricing-faq {
    width: 731px;
    margin: 101px;
}
</style>
