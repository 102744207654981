<template>
    <IonPage>
    <IonHeader>
        <IonToolbar class="header-toolbar">
            <IonButtons slot="start">
                <IonBackButton/>
            </IonButtons>
            <IonTitle>Creator Plans</IonTitle>
        </IonToolbar>
        <IonToolbar class="header-toolbar">
            <IonSegment v-model="activeIndex">
                <IonSegmentButton
                    v-for="(plan, i) in creatorPlans"
                    :key="i"
                    :value="i"
                >
                    {{ plan.name }}
                </IonSegmentButton>
            </IonSegment>
        </IonToolbar>
    </IonHeader>
    <IonContent class="ion-content">
        <CreatorPlanSwiper v-model:active-index="activeIndex"/>
    </IonContent>
    <IonFooter>
        <IonToolbar class="footer-toolbar">
            <div class="flex flex--100 plan-pricing-section">
                <h2 class="plan-name">{{ selectedPlan.name }}</h2>
                <span class="plan-price">
                    {{ selectedPlan.monthlyPrice }}€
                    <span class="plan-price-interval">/ month</span>
                </span>
                <div class="flex flex--100 flex--x-align-center">
                    <span class="plan-action-button" @click="subscribe">
                        Subscribe Now
                    </span>
                </div>
                <div class="flex flex--100 flex--x-align-center">
                    <span class="link" @click="openTermsAndConditions">
                        Terms & Conditions
                    </span>
                </div>
            </div>
        </IonToolbar>
    </IonFooter>
    </IonPage>
</template>

<script>
import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonFooter,
    IonHeader,
    IonPage,
    IonSegment,
    IonSegmentButton,
    IonTitle,
    IonToolbar, isPlatform,
} from "@ionic/vue";
import StripeSubscriptionForm from "@/components/billings/StripeSubscriptionForm.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import ApplicationIcon from "@/components/ApplicationIcon.vue";
import CreatorPlanSwiper from "@/components/creators/plans/CreatorPlanSwiper.vue";
import { creatorPlans, } from "@/utilities/PricingPlans";
import { mapStores, } from "pinia";
import { useUtilityStore, } from "@/stores/UtilityStore";
import { configureRevenueCat, getProducts, purchaseProduct, } from "@/payments/RevenueCat";
import { userManager, } from "@/UserManager";
import { useUserStore, } from "@/stores/UserStore";
import { Browser, } from "@capacitor/browser";
import { userStore } from "@/main";

export default {
    name: "CreatorPlansView",
    components: {
        CreatorPlanSwiper,
        Swiper, ApplicationIcon, SwiperSlide,
        IonBackButton, IonButtons,
        StripeSubscriptionForm,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonFooter,
        IonSegment,
        IonSegmentButton
    },
    data () {
        return {
            activeIndex: 0,
            isWaitingRevenueCatSubscription: false,
        };
    },
    computed: {
        ...mapStores(useUserStore),
        ...mapStores(useUtilityStore),

        loggedUser () {
            return this.userStore.user;
        },

        loggedUserViewLocation () {
            return `/navigation/users/${this.loggedUser.id}`;
        },

        creatorPlans () {
            return creatorPlans;
        },

        selectedPlan () {
            return this.creatorPlans[this.activeIndex];
        },

        selectedStripePriceId () {
            return this.selectedPlan.monthlyTestStripePriceId;
        },

        sharedComponents () {
            return this.utilityStore.sharedComponents;
        },

        selectedPlanMarketplaceIdentifier () {
            if (isPlatform("ios")) {
                return this.selectedPlan.appleProductId;
            }

            if (isPlatform("android")) {
                return this.selectedPlan.monthlyGoogleProductId;
            }

            return undefined;
        },
    },
    methods: {
        subscribe () {
            if (isPlatform("ios") || isPlatform("android")) {
                this.subscribeUsingRevenueCat();
            }
            else {
                this.subscribeUsingStripe();
            }
        },

        async openTermsAndConditions () {
            await Browser.open({
                url: "https://www.iubenda.com/terms-and-conditions/32870526",
            });
        },

        subscribeUsingStripe () {
            this.sharedComponents.stripeSubscriptionModal.openModal(
                this.selectedStripePriceId,
                this.selectedPlan.name,
                async () => {
                    await userManager.billing.notifyStripeEvent();
                    await this.verifySubscription();
                },
            );
        },

        async subscribeUsingRevenueCat () {
            if (this.isWaitingRevenueCatSubscription) {
                return;
            }

            this.isWaitingRevenueCatSubscription = true;

            try {
                const revenueCatProducts = await getProducts();

                await purchaseProduct(revenueCatProducts[this.selectedPlanMarketplaceIdentifier]);
                await userManager.billing.notifyRevenueCatEvent();
                await this.verifySubscription();
            }
            catch (e) {
                alert(e.message);
            }

            this.isWaitingRevenueCatSubscription = false;
        },

        async verifySubscription () {
            const { user, } = await userManager.getUser(this.loggedUser.id);

            this.userStore.update(user);

            if (user.billing.activeCreatorSubscriptionType === this.selectedPlan.levelType) {
                if (!user.isCreator) {
                    this.$router.push("/sign-up/creator");
                }
                else {
                    this.$router.push(this.loggedUserViewLocation);
                }
            }
        }
    },
    async ionViewWillEnter () {
        await configureRevenueCat(userStore.user.id);

        alert("Configured");
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.header-toolbar {
    --border-width: 0 !important;
}

.footer-toolbar {
    --border-width: 0 !important;
    --padding-start: 0;
    --padding-end: 0;
    --padding-bottom: 0;
    --padding-top: 0;
}

.plan-pricing-section {
    position: relative;

    margin: 0;
    padding: 27px;

    background-color: rgb(255, 255, 255);
    background-image: radial-gradient(circle, rgba($brand-color, 0.3) 0%, rgb(255, 255, 255) 45%);
}

.ion-content {
    &::part(background)::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;

        width: 100%;
        height: 50px;

        background-image: linear-gradient(to bottom, transparent, rgb(255, 255, 255));

        z-index: 10;
    }
}

.plan-name {
    width: 100%;

    margin: 0;
    font-size: 27px;
    font-weight: 600;
    color: $base-text-color;
    text-align: center;
}

.plan-price {
    width: 100%;

    margin: 7px 0;
    font-size: 23px;
    font-weight: 600;
    color: $base-text-color;
    text-align: center;
}

.plan-price-interval {
    font-size: 13px;
    font-weight: 400;
    color: rgb(111, 111, 111);
}

.plan-purchase {
    margin: 7px 0 0 0;
    padding: 11px 31px;
    border-radius: 99px;

    background-color: $brand-color;

    font-weight: 500;
    font-size: 15px;
    color: rgb(249, 249, 249);

    letter-spacing: 0.008rem;
}

.plan-action-button {
    cursor: pointer;

    margin: 7px 0 0 0;
    padding: 11px 31px;
    border-radius: 99px;

    background-color: $brand-color;

    font-weight: 500;
    font-size: 19px;
    color: rgb(249, 249, 249);

    letter-spacing: 0.008rem;
}

.link {
    position: relative;
    cursor: pointer;
    transition: color 300ms;

    margin: 14px 0 0 0;
    padding: 0;

    font-size: 13px;
    font-weight: 500;

    color: rgba(11, 11, 11, 0.9);

    text-decoration: none;

    &:hover {
        color: rgb(111, 111, 111);
    }
}
</style>
