<template>
    <IonPage v-if="!isLoading">
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonBackButton/>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <div class="flex flex--100 top-section">
                <SimpleButton text="Analytics" @click="toPollStatisticsView"/>
                <SimpleButton text="QR Code & Link" @click="showSharePollOverlay"/>
            </div>

            <div class="flex flex--100 flex--x-align-center pending-section" v-if="isPending">
                <span class="pending-text">{{ pendingPollMessage }}</span>
            </div>

            <SimplePoll :id="poll.id"/>
        </IonContent>
    </IonPage>
</template>

<script>
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonTextarea,
    IonTitle,
    IonToolbar,
} from "@ionic/vue";
import { userManager, } from "@/UserManager";
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
import SimplePoll from "@/components/polls/SimplePoll.vue";
import { pollsStore } from "@/main";
import { chevronForward } from "ionicons/icons";
import SimpleButton from "@/components/buttons/SimpleButton.vue";
import { useUtilityStore, } from "@/stores/UtilityStore";

export default {
    name: "PollView",
    components: {
        SimpleButton,
        IonIcon,
        SimplePoll,
        IonPage,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonList,
        IonListHeader,
        IonItem,
        IonLabel,
        IonButtons,
        IonBackButton,
        IonButton,
        IonTextarea,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            isLoading: false,
        };
    },
    computed: {
        ...mapStores(useUserStore),
        ...mapStores(useUtilityStore),

        sharedComponents () {
            return this.utilityStore.sharedComponents;
        },

        loggedUser () {
            return this.userStore.user;
        },

        isCreator () {
            return this.loggedUser.isCreator;
        },

        poll () {
            return pollsStore.get(this.id);
        },

        publishAt () {
            return new Date(this.poll.publishAt);
        },

        isPending () {
            return this.publishAt.getTime() > Date.now();
        },

        chevronForward () {
            return chevronForward;
        },

        pendingPollMessage () {
            const publishAt = this.publishAt;
            const timeParts = publishAt.toLocaleTimeString().split(":");
            const localeDate = publishAt.toLocaleDateString();
            let message = "Poll opens";

            if ((new Date()).toLocaleDateString() !== localeDate) {
                message += ` on ${localeDate}`;
            }
            else {
                message += " today"
            }

            message += ` at ${timeParts[0]}:${timeParts[1]}`;

            return message;
        },
    },
    methods: {
        async fetch () {
            this.isLoading = true;

            pollsStore.update(await userManager.getPoll(this.id));

            this.isLoading = false;
        },

        toPollStatisticsView () {
            this.$router.push(`/navigation/polls/${this.poll.id}/statistics`);
        },

        showSharePollOverlay () {
            this.sharedComponents.sharePollOverlay.show(this.poll);
        },
    },
    created () {
        if (!this.poll) {
            this.fetch();
        }
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

::v-deep(.simple-poll) {
    margin-top: 10px;

    .bottom-section {
        height: auto;
        margin: 20px 0 0 0;
        padding: 15px 0;

        border-top: 1px solid rgb(233, 233, 233);
    }

    .category {
        top: 50%;

        transform: translateY(-50%);
    }

    border-bottom: 1px solid rgb(233, 233, 233);

    .question {
        color: $base-text-color;
    }

    .description {
        color: $base-text-color;
    }

    .option-text {
        color: rgb(66, 66, 66);

        filter: none;
        mix-blend-mode: initial;
    }

    .creator-name {
        color: $base-text-color;
    }

    .poll-creation-date,
    .creator-followers {
        color: rgb(122, 122, 122);
    }

    .metric {
        color: $base-text-color;

        filter: none;
        mix-blend-mode: initial;
    }

    .category {
        box-shadow: none;
    }

    .metrics-section {
        border-top-color: rgb(233, 233, 233);
    }

    .privacy {
        color: rgb(100, 100, 100);
    }
    .privacy-link {
        color: $strong-brand-color;
    }

    .votes-percentage {
        color: rgb(66, 66, 66);

        filter: none;
        mix-blend-mode: initial;
    }

    .option::before {
        background-color: rgb(227, 229, 224);
    }

    .option--voted::before {
        background-image: none;
        background-color: rgb(207, 225, 255);
    }
    .option--voted .option-text {
        color: $strong-brand-color;
    }

    .creator-section {
        margin-top: 18px;
    }

    .secondary-action-button__icon {
        color: $base-text-color;
    }

    .secondary-action-button__label {
        color: $base-text-color;
    }

    .action-section {
        border-top-color: rgb(233, 233, 233);
    }
    .action-button {
        background-image:
            linear-gradient(45deg, hsl(210, 80%, 79%) 0%, hsla(210, 100%, 88%, 1) 100%);
    }
}

.top-section {
    padding: 13px 21px;

    border-bottom: $base-border;
}

.simple-button {
    & + & {
        margin-left: 7px;
    }
}

.pending-section {
    margin: 0;
    padding: 15px;

    border-bottom: 0.5px solid rgba(0, 0, 0, 0.05);
}

.pending-text {
    padding: 11px 15px;

    font-size: 11px;
    font-weight: 600;
    letter-spacing: normal;
    text-transform: uppercase;

    background-image:
        linear-gradient(75deg, rgb(233, 233, 233), rgb(243, 243, 243));
    border-radius: 999px;

    color: rgba(0, 0, 0, 0.9);
}
</style>
