<template>
    <IonAccordionGroup class="flex flex--100 creator-pricing-faq" value="">
        <IonAccordion
            v-for="({ question, answer, }, i) in faqItems"
            :key="i"
            :value="i"
            class="accordion"
        >
            <IonItem slot="header" color="light">
                <IonLabel>{{ question }}</IonLabel>
            </IonItem>
            <div class="answer" slot="content">
                {{ answer }}
            </div>
        </IonAccordion>
    </IonAccordionGroup>
</template>

<script>
import { defineComponent, } from "vue";
import { IonAccordion, IonAccordionGroup, IonItem, IonLabel, } from "@ionic/vue";

const faqItems = [
    {
        question: "How can I cancel my Vottify subscription?",
        answer: `
            You can cancel your subscription through your account settings or
            by contacting customer support at support@vottify.com. Cancellation takes
            effect at the end of the current billing cycle.
        `,
    },
    {
        question: "How do I reactivate my subscription after cancellation?",
        answer: `
            You can reactivate your subscription at any time through your account settings.
            If you reactivate within 30 days of cancellation, your previous data and settings may be restored.
        `,
    },
    {
        question: "Will I receive a refund if I cancel my subscription?",
        answer: `
            No refunds are given for monthly subscriptions. Annual subscriptions may receive
            a prorated refund within 14 days of cancellation, minus any discounts and a cancellation fee.
        `,
    },
    {
        question: "What happens to my data after I cancel my subscription?",
        answer: `
            Your data will be retained for 30 days post-cancellation, during which
            you can download it. After 30 days, your data will be permanently deleted.
        `,
    },
    {
        question: "Can I change my subscription plan after subscribing?",
        answer: `
            Yes, you can upgrade your plan immediately, with a prorated charge,
            or downgrade at the end of the current billing cycle.
        `,
    },
    {
        question: "What happens if I exceed the vote limit on my plan?",
        answer: `
            If you exceed the votes limit, you will be automatically
            upgraded to the next plan level and charged the corresponding fee. For example, the Light
            plan is limited to 50,000 votes per month. If you exceed this limit, you will be automatically
            upgraded to the Standard plan and charged accordingly.
        `,
    },
    {
        question: "What happens if I cancel during the trial period?",
        answer: `
            If you cancel during your free trial period, you will not be
            charged. Ensure cancellation occurs before the trial ends to avoid billing.
        `,
    },
    {
        question: "What payment methods are accepted by Vottify?",
        answer: `
            Vottify accepts major credit cards, debit cards, and PayPal. For enterprise or
            custom subscriptions, please refer to your service agreement for specific payment terms and conditions.
        `,
    },
];

export default defineComponent({
    name: "CreatorPricingFaq",
    components: {
        IonAccordionGroup,
        IonAccordion,
        IonItem,
        IonLabel,
    },
    computed: {
        faqItems () {
            return faqItems;
        },
    },
});
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.accordion {
    &:first-child {
        border-radius: 9px 9px 0 0;
    }

    &:last-child {
        border-radius: 0 0 9px 9px;
    }
}

.answer {
    margin: 0;
    padding: 11px;

    background-color: rgb(250, 250, 250);

    color: $base-text-color;
}
</style>
