<template>
    <!-- Represents a poll preview -->
    <div class="flex flex--100 simple-poll-preview">
        <div class="flex flex--100 flex--y-align-center main-section">

            <UserAvatar class="creator-avatar" :user="createdBy"/>

            <div class="flex flex--100 creator-header-section">
                <div class="flex flex--100">
                    <span class="flex flex--y-align-center creator-name">

                        {{ visibleCreatorName }}
                        <VerifiedBadgeIcon class="badge-icon"/>

                    </span>
                </div>
                <div class="flex flex--100">
                    <span class="flex poll-creation-date">

                        {{ visibleCreatedAt }} ago

                    </span>
                </div>
            </div>

            <div class="flex flex--100 question-header-section">

                <div class="flex flex--100 question-section">
                    <span class="question">{{ question }}</span>
                </div>

                <div class="flex flex--100 answer-section" v-if="loggedUserHasVoted">
                    <span class="answer">
                        You voted «{{ poll.options[loggedUserVotedOptionIndex] }}»
                    </span>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
import { pollsStore, } from "@/main";
import UserAvatar from "@/components/UserAvatar.vue";
import VerifiedBadgeIcon from "@/components/icons/VerifiedBadgeIcon.vue";
import { timeToCompactString, } from "@/utilities/Date";

export default {
    name: "SimplePollPreview",
    components: {VerifiedBadgeIcon, UserAvatar},
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    computed: {
        poll () {
            return pollsStore.get(this.id);
        },

        createdBy () {
            return this.poll.createdBy;
        },

        loggedUserVote () {
            return this.poll.$user?.vote;
        },

        loggedUserHasVoted () {
            return !!this.loggedUserVote;
        },

        loggedUserVotedOptionIndex () {
            if (!this.loggedUserHasVoted) {
                return undefined;
            }

            return this.loggedUserVote.optionIndex;
        },

        visibleCreatorName () {
            return this.createdBy.visibleName;
        },

        createdAt () {
            return new Date(this.poll.createdAt);
        },

        visibleCreatedAt () {
            return timeToCompactString(Date.now() - this.createdAt.getTime());
        },

        question () {
            return `${this.poll.question}?`;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.simple-poll-preview {

}

.main-section {
    margin: 0;
    padding: 0;
}

.creator-avatar {
    cursor: pointer;

    width: 42px;
    height: 42px;
}

.creator-header-section {
    width: 200px;

    margin-left: 8px;
}

.creator-name {
    cursor: pointer;

    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.02rem;
    color: rgb(22, 22, 22);
}

.badge-icon {
    width: 18px;
    margin-left: 2px;

    fill: $brand-color;
}

.poll-creation-date {
    cursor: default;

    font-size: 12px;
    letter-spacing: 0.02rem;
    color: rgb(122, 122, 122);
}

.question-header-section {
    margin-top: 6px;
}

.question-section {

}

.question {
    cursor: pointer;

    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.008rem;
    color: rgb(33, 33, 33);
}

.answer-section {
    margin-top: 5px;
}

.answer {
    cursor: pointer;

    margin: 0;
    padding: 5px 10px;
    background-image: linear-gradient(to right, rgba($brand-color, 0.3) 0%, rgba($brand-color, 0.1) 100%);
    border-radius: 7px;

    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.008rem;
    color: rgb(33, 33, 33);
}
</style>

<style lang="scss">
ion-item > .simple-poll-preview {
    .main-section {
        margin: 13px 0;
    }
}
</style>
