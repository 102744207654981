import { serverManager, } from "@/ServerManager";
import { countries, } from "@/utilities/Countries";
import { systemCountry, } from "@/utilities/Utilities";

export const AUTHENTICATION_TOKEN_HEADER_KEY = "Authentication-Token";
export const AUTHENTICATION_TOKEN_STORAGE_KEY = "AuthenticationToken";
export const PREFERRED_COUNTRY_ISO_STORAGE_KEY = "PreferredCountryIso";

export const userManager = Object.freeze({
    get authenticationToken (): string | undefined {
        return window.localStorage.getItem(AUTHENTICATION_TOKEN_STORAGE_KEY) || undefined;
    },

    set authenticationToken (authenticationToken: string | undefined) {
        if (typeof authenticationToken === "string") {
            window.localStorage.setItem(AUTHENTICATION_TOKEN_STORAGE_KEY, authenticationToken);
        }
    },

    get isLogged () {
        return this.authenticationToken !== undefined;
    },

    get preferredCountryIso (): string | undefined {
        return window.localStorage.getItem(PREFERRED_COUNTRY_ISO_STORAGE_KEY) || undefined;
    },

    setPreferredCountryIso (preferredCountryIso: string | undefined) {
        if (typeof preferredCountryIso === "string") {
            window.localStorage.setItem(PREFERRED_COUNTRY_ISO_STORAGE_KEY, preferredCountryIso);
        }
    },

    get preferredCountry () {
        if (this.preferredCountryIso) {
            return countries.find((country) => country.iso2.toUpperCase() === this.preferredCountryIso);
        }

        return systemCountry;
    },

    get isAnonymous () {
        return !this.isLogged;
    },

    get authenticationHeaders () {
        const headers = {} as Record<string, string>;

        if (this.authenticationToken) {
            headers[AUTHENTICATION_TOKEN_HEADER_KEY] = this.authenticationToken;
        }

        return headers;
    },

    // <sign-in>
    async signIn (descriptor: any) {
        const response = await serverManager.post("user/sign-in", descriptor);
        const responseData = response.data;
        const authenticationToken = responseData?.result?.authenticationToken;

        if (authenticationToken) {
            this.authenticationToken = authenticationToken;
        }

        return responseData;
    },
    // </sign-in>

    // <sign-up>
    async signUp (descriptor: any) {
        const response = await serverManager.post("user/sign-up", descriptor);
        const responseData = response.data;
        const authenticationToken = responseData?.result?.authenticationToken;

        if (authenticationToken) {
            this.authenticationToken = authenticationToken;
        }

        return responseData;
    },

    async creatorSignUp (descriptor: any) {
        const response = await serverManager.post("user/creator-sign-up", descriptor);

        return response.data;
    },
    // </sign-up>

    // <users>
    user: {
        async updateBiography (biography: string) {
            return serverManager.post("user/biography", {
                biography,
            });
        },

        async updateIdentity (identity: Record<string, any>) {
            return serverManager.post("user/identity", identity);
        },

        async updatePrimaryImage (image: any) {
            const formData = new FormData();

            formData.append("image", image);

            return serverManager.post("user/profile-image", formData as any, {
                "Content-Type": "multipart/form-data",
            });
        }
    },

    async getUser (id: string) {
        return (await serverManager.get("user", { id, })).data.result;
    },

    async toggleUserFollow (targetId: string) {
        return (await serverManager.post("user/toggle-follow", { targetId, })).data.result;
    },

    async getVotedPolls () {
        return (await serverManager.get("user/voted-polls")).data.result;
    },

    async getCreatedPolls (userId: string) {
        return (await serverManager.get("user/created-polls", { userId, })).data.result;
    },
    // </users>

    // <notifications>
    async getNotifications () {
        return (await serverManager.get("notifications")).data.result;
    },

    async hitNotificationView (notificationId: string) {
        return (await serverManager.post("notification/hit-view", { notificationId, })).data.result;
    },
    // </notifications>

    // <categories>
    async getCategory (id: string) {
        return (await serverManager.get("category", { id, })).data.result;
    },

    async getCategoryOpenPolls (id: string) {
        return (await serverManager.get("category/open-polls", { id, })).data.result;
    },

    async getCategoryClosedPolls (id: string) {
        return (await serverManager.get("category/closed-polls", { id, })).data.result;
    },

    async getAllCategories () {
        return (await serverManager.get("categories")).data.result.categories;
    },

    async followCategory (categoryId: string) {
        return (await serverManager.post("category/follow", { id: categoryId, })).data.result;
    },

    async unfollowCategory (categoryId: string) {
        return (await serverManager.post("category/unfollow", { id: categoryId, })).data.result;
    },
    // </categories>

    // <feed>
    feeds: {
        async getSearchFeed () {
            return serverManager.get("feed/search");
        },
    },

    async getForYouFeed () {
        return (await serverManager.get("feed/for-you")).data.result;
    },

    async getResultsFeed () {
        return (await serverManager.get("feed/results")).data.result;
    },
    // </feed>

    // <polls>
    async createPoll (descriptor: any) {
        return (await serverManager.post("poll/create", descriptor)).data.result.poll;
    },

    async getPoll (pollId: string) {
        return (await serverManager.get("poll", {
            id: pollId,
            timestamp: Date.now().toString(),
        })).data.result.poll;
    },

    async votePoll (pollId: string, optionIndex: string) {
        return (await serverManager.post("poll/vote", {
            pollId,
            optionIndex,
        })).data.result;
    },

    async amendPollVote (pollId: string, currentOptionIndex: string, newOptionIndex: string) {
        return (await serverManager.post("poll/amend-vote", {
            pollId,
            currentOptionIndex,
            newOptionIndex,
        })).data.result;
    },

    async hitPollImpression (pollId: string) {
        return (await serverManager.post("poll/hit-impression", { id: pollId, })).data.result;
    },

    async hitPollView (pollId: string) {
        return (await serverManager.post("poll/hit-view", { id: pollId, })).data.result;
    },

    async hitPollAction (pollId: string) {
        return (await serverManager.post("poll/hit-action", { id: pollId, })).data.result;
    },

    async hitPollShare (pollId: string) {
        return (await serverManager.post("poll/hit-share", { id: pollId, })).data.result;
    },

    async commentPoll (pollId: string, body: string, parentComment: string | undefined = undefined) {
        const parameters: Record<string, string> = {
            id: pollId,
            body,
        };

        if (parentComment) {
            parameters.parentComment = parentComment;
        }

        return (await serverManager.post("poll/create-comment", parameters)).data.result;
    },

    async deletePollComment (pollId: string, commentId: string) {
        return (await serverManager.post("poll/delete-comment", {
            pollId,
            commentId,
        })).data.result.comment;
    },

    async getPollComments (pollId: string, page = 1) {
        return (await serverManager.get("poll/comments", {
            id: pollId,
            page: page as any,
        })).data.result;
    },

    async getPollComment (pollId: string, commentId: string) {
        return (await serverManager.get("poll/comment", {
            pollId,
            commentId,
        })).data.result.comment;
    },

    async toggleCommentLike (pollId: string, commentId: string) {
        return (await serverManager.post("poll/toggle-comment-like", {
            pollId,
            commentId,
        })).data.result.comment;
    },
    // </polls>

    // <search>
    async search (query: string) {
        return (await serverManager.get("search", { query, })).data.result;
    },

    async trendingQueries () {
        return serverManager.get("search/trending-queries");
    },
    // </search>

    // <newsletter>
    async registerToNewsletter (email: string) {
        return (await serverManager.post("newsletter/register", { email, })).data.result;
    },
    // </newsletter>

    // <mobile-number>
    async sendMobileNumberVerificationCode (mobileNumber: string) {
        return (await serverManager.post("mobile/send-verification-code", { mobileNumber, })).data;
    },

    async verifyMobileNumberByCode (mobileNumber: string, verificationCode: string) {
        return (await serverManager.post("mobile/verify-by-code", {
            mobileNumber,
            verificationCode,
        })).data;
    },
    // </mobile-number>

    // <configuration>
    async updateConfiguration (configuration: Record<string, any>) {
        return (await serverManager.post("user/configuration", {
            configuration,
        })).data.result;
    },
    // </configuration>

    // <billing>
    async createStripeSubscription (priceId: string) {
        return (await serverManager.post("billing/stripe/subscription", {
            priceId,
        })).data.result;
    },

    async getInvoices () {
        return (await serverManager.get("billing/stripe/invoices")).data.result;
    },

    async getActiveSubscriptions () {
        return (await serverManager.get("billing/stripe/subscriptions")).data.result;
    },

    async cancelSubscription (subscriptionId: string) {
        return (await serverManager.post("billing/stripe/subscription/cancel", {
            stripeSubscriptionId: subscriptionId,
        })).data.result;
    },

    billing: {
        notifyStripeEvent () {
            return serverManager.post("billing/stripe/event");
        },

        notifyRevenueCatEvent () {
            return serverManager.post("billing/revenue-cat/event");
        },
    },
    // </billing>

    // <contact>
    async requestDemo (descriptor: any) {
        return (await serverManager.post("contact/request-demo", {
            ...descriptor,
        })).data;
    },
    // </contact>

    integrations: {
        async revokeTwitterAccess () {
            return serverManager.post("integrations/twitter/revoke-access");
        },

        async revokeLinkedInAccess () {
            return serverManager.post("integrations/linked-in/revoke-access");
        },

        async revokeFacebookAccess () {
            return serverManager.post("integrations/facebook/revoke-access");
        },
    },

    async markAccountDeletion () {
        return (await serverManager.post("user/mark-deletion", {})).data;
    },

    signOut () {
        // TODO: Move localStorage to userStore
        window.localStorage.removeItem(AUTHENTICATION_TOKEN_STORAGE_KEY);
        //userStore.clear();
    },
});
