export const featuredPolls = [
    {
        "question": "Should I step down as CEO of Twitter",
        "description": "",
        "options": [
            "Yes",
            "No",
            "Indifference",
        ],
        "categories": [
            {
                "alternativeId": "SocialMediaTrending"
            },
        ],
        "regions": [],
        "publishAt": Date.now() - + 86400000 * 7,
        "expireAt": Date.now() + 86400000 * 7,
        "isCommentable": true,
        "createdBy": {
            "visibleName": "Elon Musk",
            "isCreator": true,
            "permissions": [],
            "createdAt": "2023-08-17T20:35:20.523Z",
            "primaryImage": "64de84bdc851fd87196d4655",
            "metrics": {
                "followersCounter": 8123234,
                "followedUsersCounter": 0,
                "votesCounter": 1,
                "viewedPolls": [
                    {
                        "poll": "64de8517c851fd87196d4697",
                        "at": "2023-08-17T20:40:59.971Z"
                    }
                ],
                "votedPolls": [
                    {
                        "poll": "64de8517c851fd87196d4697",
                        "optionIndex": 0,
                        "at": "2023-08-17T20:41:04.266Z"
                    }
                ],
                "followedCategories": [
                    {
                        "category": "64562934d38cb15ccdff6354",
                        "at": "2023-08-17T20:35:23.617Z"
                    },
                    {
                        "category": "64562936d38cb15ccdff6358",
                        "at": "2023-08-17T20:35:24.447Z"
                    },
                    {
                        "category": "64562938d38cb15ccdff635c",
                        "at": "2023-08-17T20:35:25.017Z"
                    }
                ]
            },
            "id": "64de8488c851fd87196d45fd"
        },
        "createdAt": Date.now() - + 86400000 * 7,
        "updatedAt": Date.now() - + 86400000 * 7,
        "metrics": {
            "impressionsCounter": 4231530,
            "viewsCounter": 42312438,
            "sharesCounter": 123123,
            "votesCounters": [
                1200002,
                981235
            ],
            "commentsCounter": 35836,
            "createdAt": "2023-08-17T20:37:43.969Z",
            "updatedAt": "2023-09-04T16:31:00.231Z"
        },
        "id": "header-mockup-1"
    },
    {
        "question": "Which area of support for children with disabilities do you think needs the most urgent attention",
        "description": "",
        "actionType": 1,
        "options": [
            "Access to specialised medical equipment",
            "Educational support and resources",
            "Family support services and counselling",
            "Recreational activities and social inclusion programs"
        ],
        "categories": [
            {
                "alternativeId": "GoodHealthWellBeing"
            },
            {
                "alternativeId": "QualityEducation"
            },
            {
                "alternativeId": "ReducedInequalities"
            },
            {
                "alternativeId": "PartnershipGoals"
            }
        ],
        "regions": [],
        "publishAt": Date.now() - + 86400000 * 7,
        "expireAt": Date.now() + 86400000 * 7,
        "isCommentable": true,
        "createdBy": {
            "visibleName": "Caudwell Children",
            "isCreator": true,
            "permissions": [],
            "createdAt": "2023-08-17T20:35:20.523Z",
            "primaryImage": "caudwell-children",
            "metrics": {
                "followersCounter": 8123234,
                "followedUsersCounter": 0,
                "votesCounter": 1,
                "viewedPolls": [
                    {
                        "poll": "64de8517c851fd87196d4697",
                        "at": "2023-08-17T20:40:59.971Z"
                    }
                ],
                "votedPolls": [
                    {
                        "poll": "64de8517c851fd87196d4697",
                        "optionIndex": 0,
                        "at": "2023-08-17T20:41:04.266Z"
                    }
                ],
                "followedCategories": [
                    {
                        "category": "64562934d38cb15ccdff6354",
                        "at": "2023-08-17T20:35:23.617Z"
                    },
                    {
                        "category": "64562936d38cb15ccdff6358",
                        "at": "2023-08-17T20:35:24.447Z"
                    },
                    {
                        "category": "64562938d38cb15ccdff635c",
                        "at": "2023-08-17T20:35:25.017Z"
                    }
                ]
            },
            "id": "64de8488c851fd87196d45fd"
        },
        "createdAt": Date.now() - + 86400000 * 7,
        "updatedAt": Date.now() - + 86400000 * 7,
        "metrics": {
            "impressionsCounter": 4231530,
            "viewsCounter": 42312438,
            "sharesCounter": 123123,
            "votesCounters": [
                1200002,
                981235
            ],
            "commentsCounter": 35836,
            "createdAt": "2023-08-17T20:37:43.969Z",
            "updatedAt": "2023-09-04T16:31:00.231Z"
        },
        "id": "header-mockup-2"
    },
    {
        "question": "How should Shell enhance its efforts to promote sustainability in your perspective",
        "description": "",
        "options": [
            "Investing more in renewable energy like solar and wind power",
            "Supporting communities affected by fossil fuel operations",
            "Innovating to develop cleaner and more efficient fuels and technologies"
        ],
        "categories": [
            {
                "alternativeId": "AffordableCleanEnergy"
            },
            {
                "alternativeId": "LifeOnLand"
            },
            {
                "alternativeId": "ClimateAction"
            },
        ],
        "regions": [],
        "publishAt": Date.now() - + 86400000 * 7,
        "expireAt": Date.now() + 86400000 * 7,
        "isCommentable": true,
        "createdBy": {
            "visibleName": "Shell",
            "isCreator": true,
            "permissions": [],
            "createdAt": "2023-08-17T20:35:20.523Z",
            "primaryImage": "shell",
            "metrics": {
                "followersCounter": 8123234,
                "followedUsersCounter": 0,
                "votesCounter": 1,
                "viewedPolls": [
                    {
                        "poll": "64de8517c851fd87196d4697",
                        "at": "2023-08-17T20:40:59.971Z"
                    }
                ],
                "votedPolls": [
                    {
                        "poll": "64de8517c851fd87196d4697",
                        "optionIndex": 0,
                        "at": "2023-08-17T20:41:04.266Z"
                    }
                ],
                "followedCategories": [
                    {
                        "category": "64562934d38cb15ccdff6354",
                        "at": "2023-08-17T20:35:23.617Z"
                    },
                    {
                        "category": "64562936d38cb15ccdff6358",
                        "at": "2023-08-17T20:35:24.447Z"
                    },
                    {
                        "category": "64562938d38cb15ccdff635c",
                        "at": "2023-08-17T20:35:25.017Z"
                    }
                ]
            },
            "id": "64de8488c851fd87196d45fd"
        },
        "createdAt": Date.now() - + 86400000 * 7,
        "updatedAt": Date.now() - + 86400000 * 7,
        "metrics": {
            "impressionsCounter": 4231530,
            "viewsCounter": 42312438,
            "sharesCounter": 123123,
            "votesCounters": [
                1200002,
                981235
            ],
            "commentsCounter": 35836,
            "createdAt": "2023-08-17T20:37:43.969Z",
            "updatedAt": "2023-09-04T16:31:00.231Z"
        },
        "id": "header-mockup-3"
    },
];
