<template>
    <div class="flex flex--100 flex--y-align-center simple-category">

        <div class="flex flex--70">
            <div class="flex flex--100">

                <h2 class="name">{{ name }}</h2>

            </div>
            <div class="flex flex--100">

                <FollowButton
                    :value="isFollowed"
                    @click="onFollowButtonPressed"
                ></FollowButton>

            </div>
            <div class="flex flex--100">

                <template v-if="!hasShortDescription || fullDescriptionIsVisible">

                    <p class="description">{{ description }}</p>

                </template>
                <template v-else>

                    <p class="description">
                        {{ shortDescription }}...
                        <br><br>
                        <span
                            class="read-more-button"
                            @click="showFullDescription"
                        >
                        read more...
                    </span>
                    </p>

                </template>

            </div>
        </div>

        <div class="flex flex--30 flex--x-align-end">

            <SimpleCategoryIcon :alternative-id="category.alternativeId"/>

        </div>

    </div>
</template>

<script>
import { mapStores, } from "pinia";
import { useUserStore, } from "@/stores/UserStore";
import { userManager, } from "@/UserManager";
import SimpleCategoryIcon from "@/components/categories/SimpleCategoryIcon.vue";
import FollowButton from "@/components/buttons/FollowButton.vue";

const shortDescriptionLength = 980;

export default {
    name: "SimpleCategory",
    components: {FollowButton, SimpleCategoryIcon},
    props: {
        category: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            fullDescriptionIsVisible: false,
        };
    },
    computed: {
        ...mapStores(useUserStore),

        loggedUser () {
            return this.userStore.user;
        },

        id () {
            return this.category.id;
        },

        name () {
            return this.category.name;
        },

        description () {
            return this.category.description;
        },

        shortDescription () {
            const description = this.description;

            if (description.length > shortDescriptionLength) {
                return `${description.slice(0, shortDescriptionLength)}`
            }

            return description;
        },

        hasShortDescription () {
            return this.description !== this.shortDescription;
        },

        isFollowed () {
            return !!this.userStore.getFollowedCategory(this.id);
        },
    },
    methods: {
        async onFollowButtonPressed (e) {
            e.stopImmediatePropagation();

            await this.toggleFollow();
        },

        async toggleFollow () {
            if (!this.isFollowed) {
                await this.follow(this.id);
            }
            else {
                await this.unfollow(this.id);
            }
        },

        async follow () {
            const { user, } = await userManager.followCategory(this.id);

            this.userStore.update(user);
        },

        async unfollow () {
            const { user, } = await userManager.unfollowCategory(this.id);

            this.userStore.update(user);
        },

        showFullDescription () {
            this.fullDescriptionIsVisible = true;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.simple-category {
    padding: 24px 32px;

    & + & {
        border-top: $base-border;
    }
}

.name {
    margin: 0;

    font-weight: 600;
    font-size: 16px;
    color: $base-text-color;

    letter-spacing: 0.008rem;
}

.description {
    margin: 0;

    font-weight: 400;
    font-size: 14px;
    color: rgb(90, 90, 90);

    letter-spacing: 0.006rem;
}

.follow-button {
    margin: 9px 0;
}

.read-more-button {
    cursor: pointer;

    font-weight: 400;
    font-size: 14px;
    color: rgb(160, 160, 160);

    letter-spacing: 0.006rem;

    &:hover {
        opacity: 0.5;
    }
}

.simple-category-icon {
    width: 64px;

    ::v-deep(.category-icon) {
        width: 64px;
        height: 64px;
    }
}
</style>

<style lang="scss">
ion-item {
    .simple-category {
        margin: 13px 0;
        padding: 0 !important;

        .name {
            margin-bottom: 3px;
        }

        .simple-category-icon {
            width: 53px;
            margin-right: 7px;

            .category-icon {
                width: 53px;
                height: 53px;
            }
        }
    }
}
</style>
