<template>
    <div class="flex flex--100 flex--x-align-center creator-pricing-preview">
        <div class="flex flex--100 flex--x-align-center">
            <h2 class="title">
                Business Pricing
            </h2>
        </div>
        <div class="flex flex--100 flex--x-align-center">
            <div class="flex flex--80 flex--x-align-center">
                <div
                    class="flex plan-section"
                    v-for="(plan, i) in creatorPlans"
                    :key="i"
                    @click="toPricingView"
                >
                    <div class="flex flex--100 plan-pricing-section">
                        <h2 class="plan-name">{{ plan.name }}</h2>
                        <span class="plan-price">
                            {{ plan.monthlyPrice }}€
                            <span class="plan-price-interval">/ month</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, } from "vue";
import { creatorPlans, } from "@/utilities/PricingPlans";
import ApplicationMarketplaceButtons from "@/components/website/ApplicationMarketplaceButtons.vue";

export default defineComponent({
    name: "CreatorPricingPreviewSection",
    components: {ApplicationMarketplaceButtons},
    computed: {
        creatorPlans () {
            return creatorPlans;
        },
    },
    methods: {
        toPricingView () {
            this.$router.push("/pricing");
        },
    },
});
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.creator-pricing-preview {
    margin: 0;
    padding: 0;
}

.plan-section {
    transition: opacity 300ms;

    cursor: pointer;
    overflow: hidden;

    width: 30%;
    margin: 6px;

    border-radius: 12px;

    &:hover {
        opacity: 0.7;
    }

    @media (max-width: $mobile-breaking-point) {
        width: 100%;
    }
}

.plan-pricing-section {
    padding: 23px;

    background-color: rgb(255, 255, 255);
    background-image: linear-gradient(45deg, rgb(0, 0, 0) 50%, $brand-color);
}

.plan-name {
    width: 100%;

    margin: 0;
    font-size: 29px;
    font-weight: 600;
    color: rgb(255, 255, 255);
    text-align: center;
}

.plan-price {
    width: 100%;

    margin: 13px 0;
    font-size: 31px;
    font-weight: 600;
    color: rgb(255, 255, 255);
    text-align: center;
}

.plan-price-interval {
    font-size: 15px;
    font-weight: 400;
    color: rgb(211, 211, 211);
}

.plan-action-button {
    cursor: pointer;

    margin: 7px 0 0 0;
    padding: 11px 31px;
    border-radius: 99px;

    background-color: $brand-color;

    font-weight: 500;
    font-size: 19px;
    color: rgb(255, 255, 255);

    letter-spacing: 0.008rem;
}

.title {
    width: 100%;

    text-align: center;

    margin: 0 0 31px 0;
    font-size: 51px;
    font-weight: 800;
    color: rgb(23, 23, 23);
}
</style>
