<template>
    <IonPage>
    <IonContent ref="content">
        <MainNavigationBar/>
        <div class="flex flex--100">
            <WebsiteLightHeader
                :title="presentation.title"
                :subtitle="presentation.subtitle"
            />
            <div class="flex flex--100 flex--x-align-center features-navigation">
                <div class="flex flex--80">
                    <IonSegment :value="activeFeature">
                        <IonSegmentButton
                            class="feature-navigation-button"
                            v-for="(feature, i) in features"
                            :key="i"
                            :value="feature.id"
                            @click="scrollToFeature(feature.id)"
                        >
                            {{ feature.shortTitle }}
                        </IonSegmentButton>
                    </IonSegment>
                </div>
            </div>
            <div class="flex flex--100 flex--x-align-center features-section">
                <FeatureOverview
                    v-for="(feature, i) in presentation.features"
                    :key="i"
                    :presentation="feature"
                    :ref="feature.id"
                    @viewport-entered="onFeatureViewportEntered"
                    @viewport-exited="onFeatureViewportExited"
                />
            </div>
            <CreatorPricingPreviewSection/>
        </div>
        <MainFooter/>
    </IonContent>
    </IonPage>
</template>

<script>
import { IonContent, IonPage, IonSegment, IonSegmentButton, } from "@ionic/vue";
import CreatorPricingPreviewSection from "@/components/website/CreatorPricingPreview.vue";
import MainFooter from "@/components/website/WebsiteMainFooter.vue";
import MainNavigationBar from "@/components/website/WebsiteMainNavigationBar.vue";
import CategorisationIntroduction from "@/components/website/ContentCategorisation.vue";
import { mapStores } from "pinia";
import { usePollsStore } from "@/stores/PollsStore";
import FeatureOverview from "@/components/website/FeatureOverview.vue";
import WebsiteMainHeader from "@/components/website/WebsiteMainHeader.vue";
import WebsiteLightHeader from "@/components/website/TargetCustomerHeader.vue";
import { analyticsFeature, integrationsFeature, sharingFeature, } from "@/utilities/ProductSheet";

const features = [
    {
        id: "drive-company-engagement-and-action",
        screenshotUri: "/assets/mockups/company-poll.png",
        screenshotPosition: "left",
        title: "Drive Engagement and Action with Real-Time Polls",
        shortTitle: "Drive Engagement and Action",
        paragraphs: [
            {
                title: "Real-Time Audience Feedback",
                body: `
                    Create interactive polls to get instant feedback on product ideas, customer preferences,
                    and market trends, allowing you to refine your offerings based on real opinion data.
                `,
            },
            {
                title: "Customer Engagement Made Easy",
                body: `
                    Engage your audience in a dynamic way, encouraging them to vote and share their opinions.
                    Polls foster deeper connections and build brand loyalty by making your customers feel heard.
                `,
            },
            {
                title: "Call-to-Action Tools for Conversions",
                body: `
                    Convert engagement into action with powerful call-to-action features.
                    Drive traffic to your website, promote special offers, collect leads,
                    or encourage purchases directly through your polls.
                `,
            },
        ],
    },
    analyticsFeature(),
    sharingFeature(),
    integrationsFeature(),
];

export default {
    name: "WebsiteForCompaniesView",
    components: {
        WebsiteLightHeader,
        WebsiteMainHeader,
        FeatureOverview,
        CategorisationIntroduction,
        MainNavigationBar,
        MainFooter,
        CreatorPricingPreviewSection,
        IonPage,
        IonContent,
        IonSegment,
        IonSegmentButton,
    },
    data () {
        return {
            activeFeature: undefined,
            isScrollingToFeature: false,
        };
    },
    computed: {
        ...mapStores(usePollsStore),

        presentation () {
            return {
                title: `
                    Leverage Audience Insights & Amplify Your Brand's Voice
                `,
                subtitle: `
                     In today's competitive marketplace, understanding and engaging your audience
                     isn't just a necessity — it's your key strategic advantage.
                `,
                features,
            };
        },

        features () {
            return features;
        },
    },
    methods: {
        onFeatureViewportEntered (featureId) {
            this.activeFeature = featureId;
        },

        onFeatureViewportExited (featureId) {
            if (this.activeFeature === featureId) {
                this.activeFeature = undefined;
            }
        },

        async scrollToFeature (featureId) {
            if (this.isScrollingToFeature) {
                return;
            }

            const featureElement = this.$refs[featureId]?.[0]?.$el;
            this.isScrollingToFeature = true;

            await featureElement.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });

            this.isScrollingToFeature = false;
        },
    },
    ionViewWillLeave () {
        this.$refs.content.$el.scrollToTop();
    },
};
</script>

<style lang="scss" scoped>
@import "@/theme/palette.scss";

.features-section {
    margin: 111px 0;
}

.feature-overview {
    scroll-margin-top: 123px;
}

.features-navigation {
    position: sticky;
    top: 113px;

    padding: 19px;

    background-color: rgb(241, 241, 241);
    background-image: linear-gradient(45deg, rgb(241, 241, 241), rgba($brand-color, 0.15), rgb(241, 241, 241));

    z-index: 100;

    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;

        width: 100%;
        height: 0.5px;

        background-color: rgb(220, 220, 220);
        z-index: 54;
    }

    @media (max-width: $mobile-breaking-point) {
        display: none;
    }
}

.feature-navigation-button {
    font-size: 15px;
    font-weight: 400;

    &.segment-button-checked {
        font-weight: 500;
    }
}

.creator-pricing-preview {
    margin-bottom: 111px;
}
</style>
